var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"panelForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"group_id","rules":"required","name":"The Group"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"group_id","size":"large","filterable":"","clearable":"","placeholder":"Group","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Group',"list":_vm.groupList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.group_id),callback:function ($$v) {_vm.$set(_vm.formData, "group_id", $$v)},expression:"formData.group_id"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","rules":"required","name":"The Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"name","name":"name","fou":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"ident","rules":"required","name":"The Ident"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"disabled":_vm.editMode === true,"label":"Panel Ident","name":"ident","fou":""},model:{value:(_vm.formData.ident),callback:function ($$v) {_vm.$set(_vm.formData, "ident", $$v)},expression:"formData.ident"}})]}}],null,true)}),_c('fg-input',{attrs:{"type":"text","label":"Main Heading","name":"main_heading","fou":""},model:{value:(_vm.formData.main_heading),callback:function ($$v) {_vm.$set(_vm.formData, "main_heading", $$v)},expression:"formData.main_heading"}})],1),_c('div',{staticClass:"col-md-12 com-sm-12 mb-2 mt-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Content")]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":{
                  height: 400,
                  paste_data_images: true,
                  plugins: [
                  "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                   "searchreplace wordcount visualblocks visualchars code fullscreen",
                  "insertdatetime media nonbreaking save table contextmenu directionality",
                  "emoticons template paste textcolor colorpicker textpattern"
                  ],
                  toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                  toolbar2: "print preview media | forecolor backcolor emoticons",
                  image_advtab: true,
                }},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1)]),_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Image")]),_c('image-uploader',{attrs:{"preview-width":'200px',"preview-height":'200px'},model:{value:(_vm.formData.image),callback:function ($$v) {_vm.$set(_vm.formData, "image", $$v)},expression:"formData.image"}})],1),_c('fg-input',{attrs:{"type":"text","label":"Destination Url","name":"destination_url","fou":""},model:{value:(_vm.formData.destination_url),callback:function ($$v) {_vm.$set(_vm.formData, "destination_url", $$v)},expression:"formData.destination_url"}}),_c('fg-select',{attrs:{"name":"url_target","size":"large","filterable":"","clearable":"","placeholder":"Redirection Type","input-classes":'select-default',"label":'Target',"list":_vm.redirectionTargets,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.url_target),callback:function ($$v) {_vm.$set(_vm.formData, "url_target", $$v)},expression:"formData.url_target"}}),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Is Active")]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)],1)])]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/panels/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }