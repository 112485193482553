<template>

  <div class="row" ref="panelForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-8 col-sm-12">

                <ValidationProvider
                  vid="group_id"
                  rules="required"
                  name="The Group"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="group_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Group"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Group'"
                    :list="groupList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.group_id">
                  </fg-select>
                </ValidationProvider>

                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="name"
                            name="name"
                            fou
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="ident"
                  rules="required"
                  name="The Ident"
                  v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :disabled="editMode === true"
                          label="Panel Ident"
                          name="ident"
                          fou
                          v-model="formData.ident">
                </fg-input>
                </ValidationProvider>


                <fg-input type="text"
                            label="Main Heading"
                            name="main_heading"
                            fou
                            v-model="formData.main_heading">
                  </fg-input>
              </div>

              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='{
                    height: 400,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                  />
                </div>

              </div>


              <div class="col-md-8 col-sm-12">

                <div class="form-group">
                  <label>Image</label>
                  <image-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.image"></image-uploader>
                </div>

                  <fg-input type="text"
                            label="Destination Url"
                            name="destination_url"
                            fou
                            v-model="formData.destination_url">
                  </fg-input>

                <fg-select
                  name="url_target"
                  size="large"
                  filterable
                  clearable
                  placeholder="Redirection Type"
                  :input-classes="'select-default'"
                  :label="'Target'"
                  :list="redirectionTargets"
                  :listItemLabel="'label'"
                  :listItemValue="'value'"
                  v-model="formData.url_target">
                </fg-select>

                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/panels/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    FgSelect,
    LSwitch,
    ImageUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        group_id: "",
        name: "",
        ident: "",
        main_heading: "",
        content: "",
        image: "",
        destination_url: "",
        url_target: "",
        is_active: true,
      },

      groupList: [],
      redirectionTargets: []

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.panelForm
    });

    this.axios.get("panels/builder").then((response) => {
      this.groupList = response.data.groups;
      this.redirectionTargets = response.data.redirectionTargets;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Panel";
        this.getPanel();
      } else {
        this.editMode = false;
        this.formTitle = "Add Panel";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getPanel() {
      this.axios.get("panels/get/" + this.id).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Panel Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("panels/update/" + this.id, this.formData);
        successMessage = "Panel Updated Successfully";
      } else {
        request = this.axios.post("panels/create", this.formData);
        successMessage = "Panel Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/panels/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    }

  }
}
</script>
